@mixin tractebel-components-theme($theme) {
  /* Theming map for extracting colors */
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  /* Colors to be used in your styles */
  // Primary-based colors
  $dark-primary: mat-color($primary, darker);
  $real-primary: mat-color($primary);
  $light-primary: mat-color($primary, lighter);

  // Accent-based colors
  $dark-accent: mat-color($accent, darker);
  $real-accent: mat-color($accent);
  $light-accent: mat-color($accent, lighter);

  // Warn-based colors
  $dark-warn: mat-color($warn, darker);
  $real-warn: mat-color($warn);
  $light-warn: mat-color($warn, lighter);

  // Background-based colors
  $background-color: mat-color($background, background);
  $hover-color: mat-color($background, hover);
  $hint-color: mat-color($foreground, hint-text);
  $label-color: mat-color($foreground, label);
  $disabled-text: mat-color($foreground, disabled-text);
  $disabled-color: mat-color($background, unselected-chip);

  // Foreground-based colors
  $text-color: mat-color($foreground, text);
  $light-gray: mat-color($foreground, contrast);
  $gray: mat-color($foreground, secondary-text);
  $foreground-divider-color: mat-color($foreground, divider);

  /* Others */
  $default-border-radius: 8px;
  $white: #fff;
  $black: #000;

  $opacity-default: 0.8;
  $tooltip-anchor-color: rgba(0, 0, 0, 0.12) !important;

  $transition-seconds: 0.2s;
  $transition-effect: cubic-bezier(0.35, 0, 0.25, 1);
  $transition-bg: background-color $transition-seconds $transition-effect;
  $transition-all: all $transition-seconds $transition-effect;
  $transition-opacity: opacity $transition-seconds $transition-effect;

  /* Component-specific stylings*/

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    span,
    mat-icon,
    .spinner-content {
      visibility: hidden;
    }
  }

  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #0af;
    animation: spinner 1s linear infinite;
  }

  .triangle-dialog-buttons {
    padding-top: 16px;
  }

  .triangle-dialog-content {
    height: 100%;
    overflow: scroll;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.03);
  }

  .triangle-bg-primary {
    background-color: $real-primary;
  }

  .triangle-page {
    padding: 40px 0 20px 0;
  }

  .triangle-icon-primary {
    mat-icon {
      color: $real-primary;
    }
  }

  .triangle-color-primary {
    color: $real-primary;
  }

  .triangle-drawer-icons {
    .mat-icon {
      color: $real-accent;
    }
  }

  .layout-drawer-item {
    cursor: pointer;
    padding: 0 40px 0;
    .mat-icon {
      margin-top: -2px;
    }
  }

  .layout-drawer-item:hover {
    color: $real-accent;
  }

  .triangle-accent {
    color: $real-accent;
  }

  .triangle-warn {
    color: $real-warn;
  }

  .foreground-accent {
    color: $real-accent;
  }

  .triangle-table-container {
    overflow: auto;
    height: 100%;
    background: rgba(245, 245, 245);

    a {
      color: $real-accent;
    }
  }

  .triangle-page-content {
    @include mat-elevation(2);
    padding: 16px;
    border-radius: 8px;
  }

  .triangle-list-content {
    @include mat-elevation(2);
    width: 50%;
    height: 400px;
    padding: 16px;
    border-radius: 16px;
  }

  .triangle-bordered-container {
    border-radius: $default-border-radius;
  }

  .triangle-iconized-button {
    mat-icon {
      padding: 0 4px 4px 0;
    }
  }

  .triangle-list-filter {
    transition: ease 0.5s;
    width: 45%;
    opacity: 0.5;

    &:hover,
    &:focus,
    &:active {
      transition: ease 0.5s;
      opacity: 1;
    }
  }

  .full-opacity {
    transition: ease 0.5s;
    opacity: 1;
  }

  .quarter-opacity {
    transition: ease 0.5s;
    opacity: 0.25;
  }

  .hide-button-text {
    .mat-button-wrapper {
      visibility: hidden;
    }
  }

  .rounded-corners-subtle {
    border-radius: 4px;
  }

  .rounded-corners {
    border-radius: 16px;
  }
}
