@import '~@angular/material/theming';

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
}

// Workaround to hidden directive issue conflicting css when host display is block
[hidden] {
  display: none !important;
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse-in {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
}

@keyframes pulse-out {
  from {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.hide {
  opacity: 0;
  transition: ease 0.25s;
}

.half-opacity {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.bg-red {
  background: red;
}

.bg-blue {
  background-color: blue;
}

.half-viewport-height {
  height: 50vh;
}

.half-width {
  width: 50vw;
}

.three-fourths-width {
  width: 75vw;
}

.overflow {
  overflow: auto;
  overflow-x: hidden;
}

.text-align-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.color-light-grey {
  color: rgba(0, 0, 0, 0.25) !important;
}

.bg-subtle {
  background-color: rgba(0, 0, 0, 0.05);
}

/** HTML overrides */
a {
  transition: ease 0.5s;
  text-decoration: none;
  color: #000;

  &:hover {
    transition: ease 0.5s;
    text-decoration: underline;
  }
}

h1 {
  font-size: 2em !important;
}

/** Default styling overrides */

.mat-checkbox-label {
  white-space: normal !important;
}

.mat-raised-button,
.mat-flat-button,
.mat-button,
.mat-mini-fab {
  @extend .uppercase;
  @include mat-elevation-transition;
  border-radius: 2em !important;
}

.mat-button,
.mat-flat-button {
  mat-icon {
    margin-right: 0.25em;
    font-size: 1.25rem;
  }
}

.mat-drawer-container {
  background-color: #fff !important;
}

.mat-tooltip {
  background-color: rgba(0, 0, 0, 0.5);
}

.watch-video-dialog .mat-dialog-container {
  padding: 0;
  overflow: initial;
}

.form-field-icon {
  margin-right: 12px;
}

.preference-link {
  color: #007acd !important;
  font-weight: 700;
}

.actions-primary {
  background-image: linear-gradient(to right, #00a9fe, #0180c9) !important;
  padding: 4px 32px !important;
}
